/* eslint-disable no-param-reassign */
import React, { useCallback, useEffect, useState, useContext, useMemo } from 'react'
import { Typography } from '@material-ui/core'
import Button from 'components/Button'
import { useStyles } from './styles'
import { Controller, useFormContext } from 'react-hook-form'
import DateFnsUtils from '@date-io/date-fns'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import moment from 'moment'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ConfirmDialog from 'components/ConfirmDialog'
import { toast } from 'react-toastify'
import { getReadableErrorMessage } from 'utils/errors'
import { post } from '../../../../../utils/api'
import { useMutation } from 'react-query'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import RadioGroup from '@material-ui/core/RadioGroup'
import Radio from '@material-ui/core/Radio'
import { forEach, cloneDeep, pick, omit } from 'lodash'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import { GlobalSpinnerActionsContext } from 'containers/App/components/GlobalSpinnerContextProvider'
import { EWALLET_PROVIDER, EWALLET_REGION, NIUM_ONBOARD_STATUS, NIUM_STAKEHOLDER_TYPE } from 'constants.js'
import FormHelperText from '@material-ui/core/FormHelperText'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ErrorIcon from '@material-ui/icons/Error'
import { FormStructureProviderMap } from './form-schemas/providerForms'
import { TYPE } from './form-schemas/formTypes'
// sub-components
import DebouncedTextField from './sub-components/DebounceTextField'
import CountryComponent from './sub-components/CountryComponent'
import SelectComponent from './sub-components/SelectComponent'
import FileComponent from './sub-components/FileComponent'
import { CustomAccordion, CustomAccordionActions, CustomAccordionContent, CustomAccordionTitle } from './sub-components/CustomAccordions'
import { businessTypePositionMap, corporateDocumentTypeEu, formatValueMap, personDocumentTypeEu } from './form-schemas/nium/values'
import { useIntl } from 'react-intl'
import { stakeholderDetailsDocumentDetailsPerson, stakeholderDetailsTaxDetails, stakeHolderProfessionDetails, documentDetailsPerson as euDocumentDetailsPerson, applicantProfessionalDetails, applicantDetailsFields } from './form-schemas/nium/schemas/formSchemaEU'
import FormSelect from 'components/FormSelect'
import { MultiTextField } from './sub-components/MultiTextField'

function CopyFromIndividualStakeholderControl({
  onCopy,
  options,
}) {
  const classes = useStyles()
  const { formatMessage } = useIntl()
  const [value, setValue] = useState(null)
  const keyTitle = useMemo(
    () => Object.fromEntries(options.map((option, i) => [i, option.firstName + ' ' + option.lastName])),
    [options],
  )

  const handleCopy = () => {
    const copyFrom = options[parseInt(value)]
    if (!copyFrom) return

    onCopy && onCopy(copyFrom)
  }
  return (
    <div className={classes.copyStakeholderControl}>
      <Typography variant="h6" gutterBottom>
        {formatMessage({ id: 'kyb-form.auto-fill-controls' })}
      </Typography>
      <div>
        <FormSelect
          label={formatMessage({ id: 'kyb-form.copy-from-individual-stakeholder' })}
          keyTitle={keyTitle}
          onChange={e => setValue(e.target.value)}
          value={value}
          fullWidth
        />
        <Button size="small" color="primary" onClick={handleCopy}>
          {formatMessage({ id: 'kyb-form.copy' })}
        </Button>
      </div>
    </div>
  )
}

const KYBForm = ({ goToNextStep, companyId, provider, signup, saveData }) => {
  const classes = useStyles()
  const [confirmOpen, setConfirmOpen] = useState(false)
  const [isPreFill, setIsPreFill] = useState(true)
  const setGlobalSpinner = useContext(GlobalSpinnerActionsContext)
  const [savedSections, setSavedSections] = useState([])
  const { formatMessage } = useIntl()
  const {
    formStructure,
    documentDetailsCorporate,
    documentDetailsPerson,
    stakeholdersFields,
    documentDetailsCorporateStakeholders,
    allDocumentTypes,
  } = FormStructureProviderMap[provider]?.(formatMessage) || {}
  const [form, setForm] = useState(formStructure)
  const [euStakeholderProfessionalDetailPositions, setEuStakeholderProfessionalDetailPositions] = useState([[]])
  const [euStakeholderDocTypes, setEuStakeholderDocTypes] = useState([[]])
  const [euApplicantProfessionalDetailPositions, setEuApplicantProfessionalDetailPositions] = useState([])
  const [euApplicantDocTypes, setEuApplicantDocTypes] = useState([])
  const [euApplicantNationality, setEuApplicantNationality] = useState()
  const [euStakeholderNationalities, setEuStakeholderNationalities] = useState([])

  const {
    providerStatus,
    data: { remarks, customerHashId } = {}
  } = signup || {}
  const mustResubmit = providerStatus === NIUM_ONBOARD_STATUS.ERROR && !!customerHashId
  const submissionErrors = (remarks && remarks.split('|')) || []

  const {
    control,
    handleSubmit,
    watch,
    getValues,
    setValue,
    unregister,
    formState: {
      errors,
      submitCount
    },
  } = useFormContext()
  // log validation errors in console
  errors && Object.keys(errors).length && console.error(errors)
  // automatically set region by provider
  useEffect(() => {
    if(provider) {
      const region = provider === EWALLET_PROVIDER.NIUM_EU ? EWALLET_REGION.EU : EWALLET_REGION.UK
      setValue('region.region', region)
    }
  }, [provider, setValue])

  // manage data driven business type - position (NIUM EU) - ON CHANGE
  const [euBusinessType, setEuBusinessType] = useState()

  const cleanupInvalidValues = useCallback(() => {
    // NOTE: Clean up old EU KYB form data
    if (provider !== EWALLET_PROVIDER.NIUM_EU) {
      return
    }

    if (getValues('businessDetails.documentDetails.documentNumber') !== undefined) {
      unregister('businessDetails.documentDetails.documentNumber')
    }

    if (getValues('businessDetails.documentDetails.documentIssuanceCountry') !== undefined) {
      unregister('businessDetails.documentDetails.documentIssuanceCountry')
    }

    if (getValues('riskAssessmentInfo.intendedUseOfAccount') !== undefined) {
      unregister('riskAssessmentInfo.intendedUseOfAccount')
    }

    if (getValues('riskAssessmentInfo.industrySector') !== undefined) {
      unregister('riskAssessmentInfo.industrySector')
    }

    if (Array.isArray(getValues('stakeholders'))) {
      const stakeholders = getValues('stakeholders')
      for (let i = 0; i < stakeholders.length; i++) {
        const documentDetails = stakeholders[i]?.stakeholderDetails?.documentDetails

        if (Array.isArray(documentDetails)) {
          for (let j = 0; j < documentDetails.length; j++) {
            if (documentDetails[j]?.documentIssuingAuthority !== undefined) {
              unregister(`stakeholders[${i}].stakeholderDetails.documentDetails[${j}].documentIssuingAuthority`)
            }
            if (documentDetails[j]?.documentIssuedDate !== undefined) {
              unregister(`stakeholders[${i}].stakeholderDetails.documentDetails[${j}].documentIssuedDate`)
            }
          }
        }
      }
    }

    if (Array.isArray(getValues('applicantDetails.documentDetails'))) {
      const documentDetails = getValues('applicantDetails.documentDetails')
      for (let i = 0; i < documentDetails.length; i++) {
        if (documentDetails[i]?.documentIssuingAuthority !== undefined) {
          unregister(`applicantDetails.documentDetails[${i}].documentIssuingAuthority`)
        }
        if (documentDetails[i].documentIssuedDate !== undefined) {
          unregister(`applicantDetails.documentDetails[${i}].documentIssuedDate`)
        }
        if (documentDetails[i]?.documentType === 'POWER_OF_ATTORNEY') {
          if (documentDetails[i].documentNumber !== undefined) {
            unregister(`applicantDetails.documentDetails[${i}].documentNumber`)
          }
          if (documentDetails[i].documentIssuanceCountry !== undefined) {
            unregister(`applicantDetails.documentDetails[${i}].documentIssuanceCountry`)
          }
        }
      }
    }

    if (getValues('riskAssessmentInfo.transactionCountries') !== undefined) {
      unregister('riskAssessmentInfo.transactionCountries')
    }
  }, [provider, getValues, unregister])

  watch(values => {
    cleanupInvalidValues()

    if(provider !== EWALLET_PROVIDER.NIUM_EU) {
      return
    }
    const businessType = values?.businessDetails?.businessType
    if (businessType && businessType !== euBusinessType) {
      setEuBusinessType(businessType)
    }

    const stakeholderPositions = values?.stakeholders?.map(
      sh => sh.stakeholderDetails?.professionalDetails?.map(d => d.position ?? null) ?? []
    ) ?? []
    setEuStakeholderProfessionalDetailPositions(prev =>
      JSON.stringify(prev) === JSON.stringify(stakeholderPositions) ? prev : stakeholderPositions
    )

    const stakeholderDocTypes = values?.stakeholders?.map(
      sh => sh.stakeholderDetails?.documentDetails?.map(d => d.documentType ?? null) ?? []
    ) ?? []
    setEuStakeholderDocTypes(prev =>
      JSON.stringify(prev) === JSON.stringify(stakeholderDocTypes) ? prev : stakeholderDocTypes
    )

    const applicantDocTypes = values?.applicantDetails?.documentDetails?.map(d => d.documentType ?? null) ?? []
    setEuApplicantDocTypes(prev =>
      JSON.stringify(prev) === JSON.stringify(applicantDocTypes) ? prev : applicantDocTypes
    )

    const applicantProfessionalDetailPositions = values?.applicantDetails?.professionalDetails?.map(d => d.position) ?? []
    setEuApplicantProfessionalDetailPositions(prev =>
      JSON.stringify(prev) === JSON.stringify(applicantProfessionalDetailPositions) ? prev : applicantProfessionalDetailPositions
    )

    if (euApplicantNationality !== values?.applicantDetails?.nationality) {
      setEuApplicantNationality(values?.applicantDetails?.nationality)
    }

    const stakeholderNationalities = values?.stakeholders?.map(sh => sh?.stakeholderDetails?.nationality)
    setEuStakeholderNationalities(prev => JSON.stringify(prev) === JSON.stringify(stakeholderNationalities) ? prev : stakeholderNationalities)
  })

  useEffect(() => {
    if (provider !== EWALLET_PROVIDER.NIUM_EU) {
      return
    }
    if(euBusinessType) {
      const filteredOptions = businessTypePositionMap[euBusinessType]
      const stakeholders = cloneDeep(form.stakeholders.fields)
      stakeholders.forEach(shFields => {
        shFields.businessPartner.fields.businessEntityType.options = { ...filteredOptions }
      })

      if (JSON.stringify(stakeholders) !== JSON.stringify(form.stakeholders.fields)) {
        form.stakeholders.fields = stakeholders
        setForm({ ...form })
      }
    }
  }, [provider, euBusinessType, form])

  useEffect(() => {
    if (provider !== EWALLET_PROVIDER.NIUM_EU) {
      return
    }
    const documentType = cloneDeep(form.businessDetails.fields.documentDetails.fields[0].documentType)
    if (euBusinessType === 'ASSOCIATION') {
      // Association: Association Deed
      documentType.options = pick(formatValueMap(corporateDocumentTypeEu, formatMessage), ['ASSOCIATION_DEED'])
    } else if (euBusinessType === 'LIMITED_LIABILITY_PARTNERSHIP') {
      // Limited Liability Partnership: Partnership Deed
      documentType.options = pick(formatValueMap(corporateDocumentTypeEu, formatMessage), ['PARTNERSHIP_DEED'])
    } else if (euBusinessType === 'PUBLIC_COMPANY') {
      // Public company: Business Registration Document
      documentType.options = pick(formatValueMap(corporateDocumentTypeEu, formatMessage), ['BUSINESS_REGISTRATION_DOC'])
    } else if (euBusinessType === 'SOLE_TRADER') {
      // Sole Trader: Business Registration Document
      documentType.options = pick(formatValueMap(corporateDocumentTypeEu, formatMessage), ['BUSINESS_REGISTRATION_DOC'])
    } else if (euBusinessType === 'TRUST') {
      // Trust: Trust Deed
      documentType.options = pick(formatValueMap(corporateDocumentTypeEu, formatMessage), ['TRUST_DEED'])
    } else if (euBusinessType === 'PRIVATE_COMPANY') {
      // Private Limited Company: Business Registration Document
      documentType.options = pick(formatValueMap(corporateDocumentTypeEu, formatMessage), ['BUSINESS_REGISTRATION_DOC'])
    } else {
      documentType.options = cloneDeep(formatValueMap(corporateDocumentTypeEu, formatMessage))
    }

    if (JSON.stringify(documentType.options) !== JSON.stringify(form.businessDetails.fields.documentDetails.fields[0].documentType.options)) {
      form.businessDetails.fields.documentDetails.fields[0].documentType.options = documentType.options
      setForm({ ...form })
    }
  }, [provider, euBusinessType, form, setForm, formatMessage])

  useEffect(() => {
    if (provider !== EWALLET_PROVIDER.NIUM_EU) {
      return
    }
    const stakeholders = cloneDeep(form.stakeholders.fields)
    stakeholders.forEach((sh, i) => {
      if (!sh.stakeholderDetails.fields.professionalDetails) return
      sh.stakeholderDetails.fields.professionalDetails.fields.forEach((_pd, i1) => {
        const pd = stakeHolderProfessionDetails(formatMessage)
        pd.position.options = { ...businessTypePositionMap[euBusinessType] }
        const p = euStakeholderProfessionalDetailPositions?.[i]?.[i1]
        if (p !== 'UBO') {
          delete pd.sharePercentage
        }
        if (!['UBO', 'PARTNER', 'TRUSTEE'].includes(p)) {
          delete pd.positionStartDate
        }
        sh.stakeholderDetails.fields.professionalDetails.fields[i1] = pd
      })

      const ps = euStakeholderProfessionalDetailPositions?.[i]
      if (ps?.some(p => ['UBO', 'PARTNER', 'TRUSTEE'].includes(p))) {
        sh.stakeholderDetails.fields['taxDetails.0'] = cloneDeep(stakeholderDetailsTaxDetails(formatMessage))
      } else {
        delete sh.stakeholderDetails.fields['taxDetails.0']
      }

      const docTypeOptions = formatValueMap(personDocumentTypeEu, formatMessage)
      for (let i1 = 0; i1 < sh.stakeholderDetails.fields.documentDetails.fields.length; i1++) {
        const dd = cloneDeep(stakeholderDetailsDocumentDetailsPerson(formatMessage))
        if (!ps?.some(p => ['UBO', 'PARTNER', 'TRUSTEE', 'SIGNATORY', 'REPRESENTATIVE'].includes(p))) {
          delete dd['document.0.document']
        }
        if (euStakeholderDocTypes?.[i]?.[i1] !== 'PASSPORT') {
          delete dd.documentExpiryDate
        }
        if (euStakeholderNationalities[i] !== 'LT') {
          dd.documentType.options = omit(docTypeOptions, ['NATIONAL_ID'])
        } else {
          dd.documentType.options = cloneDeep(docTypeOptions)
        }
        sh.stakeholderDetails.fields.documentDetails.fields[i1] = dd
      }
    })

    if (JSON.stringify(stakeholders) !== JSON.stringify(form.stakeholders.fields)) {
      form.stakeholders.fields = stakeholders
      setForm({ ...form })
    }
  }, [provider, euStakeholderProfessionalDetailPositions, euStakeholderNationalities, euStakeholderDocTypes, form, setForm, formatMessage, euBusinessType])

  useEffect(() => {
    if (provider !== EWALLET_PROVIDER.NIUM_EU) {
      return
    }

    const applicantDetails = cloneDeep(form.applicantDetails.fields)

    if (!euApplicantProfessionalDetailPositions.includes('DIRECTOR')) {
      applicantDetails.documentDetails.lockedFieldIndexes = [0, 1]

      if (applicantDetails.documentDetails.fields.length < 2) {
        applicantDetails.documentDetails.fields.push(
          cloneDeep(euDocumentDetailsPerson(formatMessage)),
        )
      }
    } else {
      applicantDetails.documentDetails.lockedFieldIndexes = [0]
    }

    for (let i = 0; i < applicantDetails.documentDetails.fields.length; i++) {
      const dd = cloneDeep(euDocumentDetailsPerson(formatMessage))

      if (i === 0) {
        Object.keys(dd).forEach(k => {
          if (![
            'documentType',
            'documentNumber',
            'documentIssuanceCountry',
            'documentExpiryDate',
          ].includes(k)) {
            delete dd[k]
          }
        })
        dd.documentType.options = pick(formatValueMap(personDocumentTypeEu, formatMessage), ['PASSPORT'])
      } else if (i === 1 && !euApplicantProfessionalDetailPositions.includes('DIRECTOR')) {
        dd.documentType.options = pick(formatValueMap(personDocumentTypeEu, formatMessage), ['POWER_OF_ATTORNEY'])
      } else if (euApplicantNationality !== 'LT') {
        dd.documentType.options = omit(formatValueMap(personDocumentTypeEu, formatMessage), ['NATIONAL_ID'])
      }

      if (euApplicantDocTypes[i] === 'POWER_OF_ATTORNEY') {
        delete dd.documentNumber
        delete dd.documentIssuanceCountry
        delete dd.documentIssuedDate
      }
      if (euApplicantDocTypes[i] !== 'POWER_OF_ATTORNEY') {
        delete dd['document.0.document']
      }
      if (euApplicantDocTypes[i] !== 'PASSPORT') {
        delete dd.documentExpiryDate
      }
      applicantDetails.documentDetails.fields[i] = dd
    }

    for (let i = 0; i < applicantDetails.professionalDetails.fields.length; i++) {
      const pd = cloneDeep(applicantProfessionalDetails(formatMessage))
      pd.position.options = { ...businessTypePositionMap[euBusinessType] }
      if (euApplicantProfessionalDetailPositions[i] !== 'UBO') {
        delete pd.sharePercentage
      }
      applicantDetails.professionalDetails.fields[i] = pd
    }

    if (JSON.stringify(applicantDetails) !== JSON.stringify(form.applicantDetails.fields)) {
      form.applicantDetails.fields = applicantDetails
      setForm({ ...form })
    }
  }, [provider, euBusinessType, euApplicantNationality, euApplicantDocTypes, euApplicantProfessionalDetailPositions, form, setForm, formatMessage])

  // manage same address onchange
  const [addressSubActive, setAddressSubActive] = useState(null)
  const copyRegisteredAddress = useCallback((values) => {
    const {
      businessDetails: {
        addresses: {
          registeredAddress
        }
      }
    } = values
    for (const key in registeredAddress) {
      const extendedKey = `businessDetails.addresses.businessAddress.${key}`
      if (getValues(extendedKey) !== registeredAddress[key]) {
        setValue(extendedKey, registeredAddress[key])
      }
    }
  }, [setValue, getValues])
  
  const clearBusinessAddress = useCallback(() => {
    for (const key in formStructure.businessDetails.fields.addresses.fields.businessAddress.fields) {
      setValue(`businessDetails.addresses.businessAddress.${key}`, '')
    }
  }, [formStructure, setValue])

  useEffect(() => {
    let unsubscribe = () => {}
    if(addressSubActive === null) {
      // if never changed, do nothing
      return
    }
    if (addressSubActive) {
      // immediate copy
      copyRegisteredAddress(getValues())
      // onchange
      unsubscribe = watch(values => {
        copyRegisteredAddress(values)
      }).unsubscribe
    } else {
      clearBusinessAddress()
    }
    return () => {
      unsubscribe() 
    }
  }, [addressSubActive, copyRegisteredAddress, watch, getValues, clearBusinessAddress])

  const manageSameAddress = (e) => {
    setAddressSubActive(e.target.value.toUpperCase() === 'YES')
  }

  const kybCorporateOnboardEWallet = (onboardInfo) => {
    return post(
      `/e-wallet/kyb-corporate-onboard`, 
      { ...onboardInfo, companyId, provider, ...(mustResubmit ? { customerHashId } : {}) }
    )
  }

  const { mutate: signupEWalletMutation, isLoading } = useMutation(kybCorporateOnboardEWallet, {
    onSuccess: (response) => {
      toast.success('KYB Wallet application sent successfully.')
      setGlobalSpinner(false)
      goToNextStep(response)
    },
    onError: (error) => {
      toast.error(getReadableErrorMessage(error))
    },
  })

  useEffect(() => {
    if (isLoading) {
      setGlobalSpinner(true)
    } else {
      setGlobalSpinner(false)
    }
  }, [isLoading, setGlobalSpinner])

  const onSubmit = handleSubmit((formData) => {
    // save on submit
    saveData()
    // build request object
    const refinedFormData = cloneDeep(formData)
    signupEWalletMutation(refinedFormData)
  })

  const expandCascade = (element, nameId) => {
    // reached top element, exit
    if(element.id === nameId) {
      return
    }
    const parent = element.parentNode
    if(parent.classList && [...parent.classList].includes('MuiCollapse-hidden')) {
      // collapsed section: find expand button and click it
      const sibling = [...parent.parentNode.childNodes].find(s => [...s.classList].includes('MuiAccordionSummary-root'))
      const expandIcon = [...sibling.childNodes].find(cn => [...cn.classList].includes('MuiAccordionSummary-expandIcon'))
      expandIcon && expandIcon.click()
    }
    return expandCascade(parent)
  }

  const afterSave = (validationResult, nameId) => {
    if(validationResult) {
      // in case of successful validation
      // find expand button by section id and click it to close the section
      document.querySelector(`#section-${nameId} .MuiAccordionSummary-expandIcon`).click()
    } else {
      // in case of failed validation - find the first error
      const firstError = document.querySelector(`#section-${nameId} .Mui-error`)
      // first expand all nodes that are hidden
      if(firstError) {
        expandCascade(firstError, nameId)
        // then scroll to the first error encountered in the section
        firstError.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }
  }

  const removeGroupItem = (origin, index) => {
    const numArray = origin.match(/\d+/g)
    const numArr = numArray && numArray[0] ? Number(numArray[0]) : undefined
    const toUpdate = watch(origin)
    if(!toUpdate) {
      return
    }
    if (origin === 'stakeholders') {
      form.stakeholders.fields.splice(index, 1)
    } else if (origin === 'businessDetails.documentDetails') {
      form.businessDetails.fields.documentDetails.fields.splice(index, 1)
    } else if (origin === `applicantDetails.documentDetails`) {
      form.applicantDetails.fields.documentDetails.fields.splice(index, 1)
    } else if (origin === `stakeholders.${numArr}.stakeholderDetails.documentDetails`) {
      form.stakeholders.fields[numArr].stakeholderDetails.fields.documentDetails.fields.splice(index, 1)
    } else if (origin === `stakeholders.${numArr}.businessPartner.documentDetails`) {
      form.stakeholders.fields[numArr].businessPartner.fields.documentDetails.fields.splice(index, 1)
    } else if (origin === `stakeholders.${numArr}.stakeholderDetails.professionalDetails`) {
      form.stakeholders.fields[numArr].stakeholderDetails.fields.professionalDetails.fields.splice(index, 1)
    } else if (origin === 'applicantDetails.professionalDetails') {
      form.applicantDetails.fields.professionalDetails.fields.splice(index, 1)
    }
    toUpdate.splice(index, 1)
    setValue(origin, toUpdate)
    setForm({ ...form })
  }


  const renderFormItem = (formItem, origin) => {
    const nameId = origin
    const isRootLevel = origin.split('.').length === 1

    if (formItem.renderIf) {
      let shouldRender = true
      const [index] = origin.match(/\d+/g)
      forEach(formItem.renderIf, (value, key) => {
        const parsedKey = key.replace('index', index)
        const watchValue = watch(parsedKey, false)
        const itChecks = watchValue === value
        if (!itChecks) {
          shouldRender = false
        }
      })
      if (!shouldRender) {
        return <></>
      }
    }

    const renderGroupHeaderContent = (item, index) => {
      let content = `${formatMessage({ id: `kyb-form.document` })} ${index + 1} *`
      if (item.documentType) {
        const documentType = watch(`${origin}.${index}.documentType`, '')
        if(documentType) {
          content += ' - ' + allDocumentTypes[documentType]
        }
        // removed since now it's multi selection
        // const documentFileName = watch(`${origin}.${index}.document.0.fileName`, '')  
        // if (documentFileName) {
        //   content += ' - ' + documentFileName
        // }
      } else if (item.stakeholderType) {
        content = `${formatMessage({ id: `kyb-form.stakeholder` })} ${index + 1}`
        const businessName = watch(`${origin}.${index}.businessPartner.businessName`, '')
        if (businessName) {
          content += ` - ${businessName}`
        } else {
          const firstName = watch(`${origin}.${index}.stakeholderDetails.firstName`, '')
          if (firstName) {
            content += ` - ${firstName}`
          }
          const lastName = watch(`${origin}.${index}.stakeholderDetails.lastName`, '')  
          if (lastName) {
            if (!firstName) {
              content += ' - '
            }
            content += ' ' + lastName
          }
        }
      } else if (item.position) {
        content = `${formatMessage({ id: `kyb-form.position` })} ${index + 1}`
      } else if (item.remitter) {
        content = `${formatMessage({ id: `kyb-form.remitter` })} ${index + 1}`
      } else if (item.beneficiary) {
        content = `${formatMessage({ id: `kyb-form.beneficiary` })} ${index + 1}`
      }
      return content || index + 1
    }

    if (formItem.type === TYPE.GROUP) {
      const showBadge = isRootLevel && (submitCount > 0 || savedSections.includes(nameId))
      let sectionContent = ''

      const extraControls = formItem.extraControls?.map(ctrl => {
        if (ctrl.type === 'COPY_FROM_STAKEHOLDER') {
          const stakeholders = (getValues('stakeholders') || [])
            .filter(sh => (
              sh.stakeholderType === NIUM_STAKEHOLDER_TYPE.INDIVIDUAL &&
              sh.stakeholderDetails &&
              sh.stakeholderDetails.firstName &&
              sh.stakeholderDetails.lastName
            ))
            .map(sh => sh.stakeholderDetails)

          return (
            <div key={`${origin}-extra-controls-${ctrl.type}`}>
              <CopyFromIndividualStakeholderControl
                key={JSON.stringify(stakeholders)}
                options={stakeholders}
                onCopy={copyFrom => {
                  let passportIdx = -1
                  let powerOfAttorneyIdx = -1
                  for (let i = 0; i < copyFrom.documentDetails.length; i++) {
                    const doc = copyFrom.documentDetails[i]
                    if (doc.documentType === 'PASSPORT' && passportIdx === -1) {
                      passportIdx = i
                    } else if (doc.documentType === 'POWER_OF_ATTORNEY' && powerOfAttorneyIdx === -1) {
                      powerOfAttorneyIdx = i
                    }
                    if (passportIdx !== -1 && powerOfAttorneyIdx !== -1) break
                  }

                  const emptyDoc = Object.fromEntries([
                    ['document', [null]],
                    ...Object.entries(euDocumentDetailsPerson(formatMessage))
                      .filter(([k]) => !/\.\d+\./g.test(k))
                      .map(([k]) => [k, '']),
                  ])
                  const docs = [
                    passportIdx === -1 ? cloneDeep(emptyDoc) : copyFrom.documentDetails[passportIdx],
                  ]
                  if (!euApplicantProfessionalDetailPositions.includes('DIRECTOR')) {
                    docs[1] = powerOfAttorneyIdx === -1 ? cloneDeep(emptyDoc) : copyFrom.documentDetails[powerOfAttorneyIdx]
                    docs.push(...copyFrom.documentDetails.filter((_, i) => i !== passportIdx && i !== powerOfAttorneyIdx))
                  } else {
                    docs.push(...copyFrom.documentDetails.filter((_, i) => i !== passportIdx))
                  }
                  setValue('applicantDetails', cloneDeep({
                    ...pick({
                      ...copyFrom,
                      dateOfBirth: new Date(copyFrom.dateOfBirth).toString() === 'Invalid Date' ? null : copyFrom.dateOfBirth,
                    }, Object.keys(applicantDetailsFields(formatMessage))),
                    documentDetails: docs.map((doc, idx) => {
                      let d = doc
                      if (idx === 0) {
                        d = pick(d, [
                          'documentType',
                          'documentNumber',
                          'documentIssuanceCountry',
                          'documentExpiryDate',
                        ])
                      }
                      if (doc.documentType === 'POWER_OF_ATTORNEY') {
                        d.documentNumber = ''
                        d.documentIssuanceCountry = ''
                        d.documentIssuedDate = ''
                      }
                      if (doc.documentType !== 'POWER_OF_ATTORNEY') {
                        d.document = [null]
                      }
                      if (doc.documentType !== 'PASSPORT') {
                        d.documentExpiryDate = ''
                      }
                      return d
                    }),
                  }))
                }}
              />
            </div>
          )
        }
        return null
      })

      if (formItem.multiple) {
        const getSpecificLabel = (code, plural = false) => {
          if (code.includes('expectedAccountUsage.debit.topBeneficiaries')) {
            return formatMessage({ id: `kyb-form.${plural ? 'beneficiaries' : 'beneficiary'}` })
          } else if (code.includes('expectedAccountUsage.credit.topRemitters')) {
            return formatMessage({ id: `kyb-form.remitter${plural ? 's' : ''}` })
          } else if (code.includes('stakeholderDetails.professionalDetails')) {
            return formatMessage({ id: `kyb-form.position${plural ? 's' : ''}` })
          } else if (code.includes('documentDetails')) {
            return formatMessage({ id: `kyb-form.document${plural ? 's' : ''}` })
          } else if (code.includes('stakeholders')) {
            return formatMessage({ id: `kyb-form.stakeholder${plural ? 's' : ''}` })
          }
          return ''
        }

        sectionContent = <>
          <CustomAccordionContent>
            <div className={classes.fullWidth}>
              {formItem.info && <Typography color="textSecondary" className={classes.info} dangerouslySetInnerHTML={{ __html: formItem.info }} />}
              {extraControls}
              {formItem.fields.map((item, index) => (
                <CustomAccordion key={index}>
                  <CustomAccordionTitle expandIcon={<ExpandMoreIcon />}>
                    <Typography className={classes.heading}>
                      {renderGroupHeaderContent(item, index)}
                    </Typography>
                  </CustomAccordionTitle>
                  <CustomAccordionContent>
                    <div style={{ width: '100%' }}>
                      {Object.entries(item).map(([k, v]) => renderFormItem(v, `${origin}.${index}.${k}`))}
                    </div>
                  </CustomAccordionContent>
                  {formItem.fields && formItem.fields.length > 1 && !formItem.lockedFieldIndexes?.includes(index) && <CustomAccordionActions>
                    <Button size="small" color="primary" onClick={() => removeGroupItem(origin, index)}>
                    {formatMessage({ id: 'tlpay.remove' })} {getSpecificLabel(origin)}
                    </Button>
                  </CustomAccordionActions>}
                </CustomAccordion>
              ))}
            </div>
          </CustomAccordionContent>
          <CustomAccordionActions>
            <Button size="small" color="primary" onClick={() => addMore(origin)}>
              {formatMessage({ id: 'tlpay.add-more' })} {getSpecificLabel(origin, true)}
            </Button>
          </CustomAccordionActions>
        </>
      } else {
        sectionContent = <>
            <CustomAccordionContent>
            <div className={classes.fullWidth}>
              {formItem.info && <Typography color="textSecondary">{formItem.info}</Typography>}
              {extraControls}
              {Object.entries(formItem.fields).map(([k, v]) => renderFormItem(v, `${origin}.${k}`))}
            </div>
          </CustomAccordionContent>
        </>
      }
      
      return (
        <CustomAccordion
        className={[
          formItem.visibleIfFalse && watch(formItem.visibleIfFalse, 'Yes').toUpperCase() !== 'NO' && classes.hidden,
          isRootLevel && classes.rootSection
        ]}
        id={`section-${nameId}`}
        {...(formItem.noExpand && { expanded: true })}
      >
        <CustomAccordionTitle 
            {...(!formItem.noExpand && { expandIcon: <ExpandMoreIcon /> })}>
          <Typography
            {...!isRootLevel && { className: classes.heading }}
            {...isRootLevel && { variant: 'h4' }}
          >
            {showBadge && (Object.hasOwn(errors, nameId) ? <ErrorIcon color="error" className={classes.icon} fontSize="small" /> : <CheckCircleIcon className={[classes.successIcon, classes.icon]} fontSize="small" />)}
            {formItem.title}
          </Typography>
        </CustomAccordionTitle>
        {sectionContent}
        <div className={classes.buttonContainerSection}>
            {isRootLevel && <Button
              disabled={isLoading}
              variant="extended"
              type="submit"
              size="large"
              aria-label="Save"
              color="primary"
              onClick={() => { 
                saveData(nameId).then(validationResult => {
                  afterSave(validationResult, nameId)
                })
                if(!savedSections.includes(nameId)) {
                  setSavedSections(prevState => [...prevState, nameId])
                }
              }}
              className={classes.buttonSpacing}
            >
              {formatMessage({ id: 'tlpay.save-data' })}
            </Button>}
          </div>
      </CustomAccordion>
      )
    }
    if (formItem.type === TYPE.STRING) {
      if (formItem.multiple) {
        return (
          <Controller
            key={nameId}
            control={control}
            name={nameId}
            render={(props) => (
              <MultiTextField
                controllerProps={props}
                label={formItem.label}
                info={formItem.info}
                required={formItem.required}
                onChange={props.field.onChange}
                value={props.field.value}
                errorMessage={
                  props.fieldState.error &&
                  formatMessage({ id: 'tlpay.is-required' })
                }
              />
            )}
          />
        )
      }
      return (
        <Controller
          key={nameId}
          control={control}
          name={nameId}
          render={(props) => (
            <DebouncedTextField
              controllerProps={props}
              label={formItem.label}
              required={formItem.required}
              info={formItem.info}
              decimalDigits={formItem.decimalDigits}
              customErrorMessageTypes={formItem.customErrorMessageTypes}
              multiline={!!formItem.multiline}
              inputProps={{
                ...(typeof formItem.maxLength === 'number' ? { maxLength: formItem.maxLength } : {}),
                ...(typeof formItem.minRows === 'number' ? { minRows: formItem.minRows } : {}),
                ...(typeof formItem.maxRows === 'number' ? { maxRows: formItem.maxRows } : {}),
              }}
            />
          )}
        />
      )
    }
    if (formItem.type === TYPE.SELECT) {
      return <SelectComponent nameId={nameId} formItem={formItem} />
    }
    if (formItem.type === TYPE.COUNTRY) {
      return <CountryComponent nameId={nameId} formItem={formItem} />
    }
    if (formItem.type === TYPE.DATE) {
      const getError = (error) => {
        const { type } = error
        if(type === 'date.max') {
          return formatMessage({ id: 'tlpay.cannot-be-set-in-the-future' })
        }
        if(type === 'date.min') {
          return formatMessage({ id: 'tlpay.cannot-be-set-in-the-past' })
        }
        if(type === 'date.format') {
          return formatMessage({ id: 'tlpay.invalid-date' })
        }
        
        return formatMessage({ id: 'tlpay.is-required' })
      }
      const today = new Date()
      const yesterday = new Date().setDate(today.getDate() - 1)
      return (
        <Controller
          key={nameId}
          render={({ field: { onChange, value = null }, fieldState }) => (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                className={classes.datePicker}
                clearable
                label={formItem.label + (formItem.required ? ' *' : '')}
                inputVariant="outlined"
                format="yyyy-MM-dd"
                onChange={(newDate) => {
                  // in case of "past" date, automatically set the date to yesterday if today is selected manually
                  if(formItem.past && newDate && newDate.getFullYear() === today.getFullYear() &&
                    newDate.getMonth() === today.getMonth() && newDate.getDate() === today.getDate()) {
                    newDate.setDate(newDate.getDate() - 1)
                  }
                  return onChange(moment(newDate).format('YYYY-MM-DD'))
                }}
                value={value}
                emptyLabel="YYYY-MM-DD"
                size='small'
                error={!!fieldState.error}
                helperText={fieldState.error && getError(fieldState.error)}
                {...(formItem.future ? { minDate: today } : {} )}
                {...(formItem.past ? { maxDate: yesterday } : {} )}
              />
              {formItem.info && <Typography color="textSecondary" className={classes.info} dangerouslySetInnerHTML={{ __html: formItem.info }} />}
            </MuiPickersUtilsProvider>
          )}
          control={control}
          name={nameId}
          error={false}
        />
      )
    }
    if (formItem.type === TYPE.FILE) {
      return <FileComponent nameId={nameId} formItem={formItem} />
    }
    if (formItem.type === TYPE.RADIO) {
      return (
        <Controller
          key={nameId}
          render={({ field, fieldState: { error } }) => (
            <FormControl
              className={[
                classes.field,
                formItem.visibleIfTrue && watch(formItem.visibleIfTrue, 'No').toUpperCase() !== 'YES' && classes.hidden,
              ]}
            >
              {formItem.label &&
                <FormLabel
                  className={classes.primaryLabel}
                  {...formItem.required && { required: true }}
                >
                  {formItem.label}
                </FormLabel>
              }
              {formItem.info &&
                <FormLabel
                  className={[classes.field, classes.primaryLabel]}
                >
                  {formItem.info}
                </FormLabel>
              }
              <RadioGroup
                row
                {...field}
                onChange={(e) => {
                  if (nameId.includes('stakeholderType')) {
                    if (e.target.value === NIUM_STAKEHOLDER_TYPE.CORPORATE) {
                      setValue(`${nameId.replace('stakeholderType','stakeholderDetails')}`, undefined)
                    }
                    if (e.target.value === NIUM_STAKEHOLDER_TYPE.INDIVIDUAL) {
                      setValue(`${nameId.replace('stakeholderType','businessPartner')}`, undefined)
                    }
                  }
                  if(nameId === 'businessDetails.addresses.isSameAddress') {
                    manageSameAddress(e)
                  }
                  field.onChange(e.target.value)
                }}
                name={nameId}
              >
                {Object.entries(formItem.options).map(([value, label]) => (
                  <FormControlLabel key={value} value={value} control={<Radio color="primary" checked={value === field.value} />} label={label} />
                ))}
              </RadioGroup>
              {error && <FormHelperText error>{formatMessage({ id: 'tlpay.is-required' })}</FormHelperText>}
            </FormControl>
          )}
          control={control}
          name={nameId}
          error={false}
        />
      )
    }
  }

  const addMore = useCallback(
    (origin) => {
      const numArray = origin.match(/\d+/g)
      const numArr = numArray && numArray[0] ? Number(numArray[0]) : undefined
      if (origin === 'stakeholders') {
        form.stakeholders.fields.push(cloneDeep(stakeholdersFields))
        setForm({ ...form })
      } else if (origin === 'businessDetails.documentDetails') {
        form.businessDetails.fields.documentDetails.fields.push(cloneDeep(documentDetailsCorporate))
        setForm({ ...form })
      } else if (origin === 'applicantDetails.documentDetails') {
        form.applicantDetails.fields.documentDetails.fields.push(cloneDeep(documentDetailsPerson))
        setForm({ ...form })
      } else if (origin === `stakeholders.${numArr}.stakeholderDetails.documentDetails`) {
        form.stakeholders.fields[numArr].stakeholderDetails.fields.documentDetails.fields.push(
          cloneDeep(documentDetailsPerson)
        )
        setForm({ ...form })
      } else if (origin === `stakeholders.${numArr}.businessPartner.documentDetails`) {
        form.stakeholders.fields[numArr].businessPartner.fields.documentDetails.fields.push(
          cloneDeep(documentDetailsCorporateStakeholders)
        )
        setForm({ ...form })
      } else if (origin === `stakeholders.${numArr}.stakeholderDetails.professionalDetails`) {
        form.stakeholders.fields[numArr].stakeholderDetails.fields.professionalDetails.fields.push(
          cloneDeep(stakeHolderProfessionDetails(formatMessage)),
        )
        setForm({ ...form })
      } else if (origin === 'applicantDetails.professionalDetails') {
        form.applicantDetails.fields.professionalDetails.fields.push(
          cloneDeep(applicantProfessionalDetails(formatMessage)),
        )
        setForm({ ...form })
      }
    },
    [form],
  )

  // manage multiple elements pre-fill
  useEffect(() => {
    const { 
      stakeholders = [],
      businessDetails: {
        documentDetails: businessDocuments = []
      } = {},
      applicantDetails: {
        documentDetails: applicantDocuments = []
      } = {},
    } = getValues()

    if (isPreFill) {
      // add stakeholders
      const shFormCount = form.stakeholders.fields.length
      if(stakeholders &&
          stakeholders.length !== shFormCount &&
          stakeholders.length > 1) {
        for (let i = shFormCount; i < stakeholders.length; i++) {
          addMore('stakeholders')
        }
      }

      // add stakeholder positions
      if (provider === EWALLET_PROVIDER.NIUM_EU) {
        for (let shIdx = 0; shIdx < getValues('stakeholders')?.length; shIdx++) {
          const sh = form.stakeholders.fields[shIdx]
          for (
            let pdIdx = sh.stakeholderDetails.fields.professionalDetails.fields.length;
            pdIdx < getValues(`stakeholders[${shIdx}].stakeholderDetails.professionalDetails`)?.length;
            pdIdx++
          ) {
            addMore(`stakeholders.${shIdx}.stakeholderDetails.professionalDetails`)
          }
        }
      }
      
      // add stakeholder documents
      for(let j = 0; j < stakeholders.length; j++) {
        const sh = stakeholders[j]
        if(sh.stakeholderType === NIUM_STAKEHOLDER_TYPE.INDIVIDUAL) {
          const {
            stakeholderDetails: {
              documentDetails
            } = {}
          } = sh
          const shIndividualDocCount = form.stakeholders.fields[j].stakeholderDetails.fields.documentDetails.fields.length
          if(documentDetails &&
              documentDetails.length !== shIndividualDocCount &&
              documentDetails.length > 1) {
            for (let i = shIndividualDocCount; i < documentDetails.length; i++) {
              addMore(`stakeholders.${j}.stakeholderDetails.documentDetails`)
            }
          }
        } else if(sh.stakeholderType === NIUM_STAKEHOLDER_TYPE.CORPORATE) {
          const {
            businessPartner: {
              documentDetails
            } = {}
          } = sh
          const shCorporateDocCount = form.stakeholders.fields[j].businessPartner.fields.documentDetails.fields.length
          if(documentDetails &&
              documentDetails.length !== shCorporateDocCount &&
              documentDetails.length > 1) {
            for (let i = shCorporateDocCount; i < documentDetails.length; i++) {
              addMore(`stakeholders.${j}.businessPartner.documentDetails`)
            }
          }
        }

      }

      // add applicant positions
      if (provider === EWALLET_PROVIDER.NIUM_EU) {
        for (
          let pdIdx = form.applicantDetails.fields.professionalDetails.fields.length;
          pdIdx < getValues('applicantDetails.professionalDetails')?.length;
          pdIdx++
        ) {
          addMore('applicantDetails.professionalDetails')
        }
      }
      
      // add applicant documents
      const applicantDocCount = form.applicantDetails.fields.documentDetails.fields.length
      if(applicantDocuments &&
          applicantDocuments.length !== applicantDocCount &&
          applicantDocuments.length > 1) {
        for (let i = applicantDocCount; i < applicantDocuments.length; i++) {
          addMore('applicantDetails.documentDetails')
        }
      }

      // add business documents
      const businessDocCount = form.businessDetails.fields.documentDetails.fields.length
      if(businessDocuments &&
          businessDocuments.length !== businessDocCount &&
          businessDocuments.length > 1) {
        for (let i = businessDocCount; i < businessDocuments.length; i++) {
          addMore('businessDetails.documentDetails')
        }
      }
      
      setIsPreFill(false)
    }
  }, [getValues, addMore, form, isPreFill])

  return (
    <Card variant="outlined" className={classes.formContainer}>
      {mustResubmit && <div style={{ padding: '1em', paddingBottom: 0 }}>
        <Typography>{formatMessage({ id: 'tlpay.there-have-been-errors-in-your-application-please-resubmit-after-correcting-the-below-errors' })}: </Typography>
        <ul>
          {submissionErrors.map((s, i) => <li style={{ marginBottom: '10px' }} key={i}> {s} </li>)}
        </ul>
      </div>}
      <CardContent>
        <Typography variant="h3" gutterBottom>
          {formatMessage({ id: 'tlpay.step-1-kyb-form' })}
        </Typography>
        {Object.entries(form).map(([k, v]) => (
          <div key={k}>{renderFormItem(v, k)}</div>
        ))}
        <div className={classes.buttonContainer}>
          <Button
            disabled={isLoading}
            variant="extended"
            type="submit"
            size="large"
            color="success"
            aria-label="add"
            onClick={() => setConfirmOpen(true)}
            className={classes.buttonSpacing}
          >
            {formatMessage({ id: 'tlpay.next' })}
          </Button>
        </div>
        
        <ConfirmDialog
          open={confirmOpen}
          setOpen={setConfirmOpen}
          onConfirm={() => onSubmit()}
          title={formatMessage({ id: 'kyb-form.confirm-and-submit-kyb-application' })}
          noText={formatMessage({ id: 'kyb-form.cancel' })}
          yesText={formatMessage({ id: 'kyb-form.submit' })}
        >
          <Typography paragraph>
            {formatMessage({ id: 'tlpay.once-submitted-your-application-will-be-reviewed-if-any-additional-information-is-required-you-will-be-notified' })}
          </Typography>
          <Typography paragraph>
            {formatMessage({ id: 'tlpay.please-note-that-you-will-not-be-able-to-edit-or-change-your-information-during-the-approval-process-outside-of-any-requests-for-additional-information' })}
          </Typography>
        </ConfirmDialog>
      </CardContent>
    </Card>
  )
}

export default KYBForm
